@import url(https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  scroll-behavior: smooth;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: #fff;
  color: #000;
  font-family: 'Poppins', sans-serif;
 
}

.container{
  max-width: 1300px;
  margin: 0 auto;
}

a{
  text-decoration: none;
  
}
img{
  width: 100%;
}

.Navbar{
  background-color: #3340FF;
  box-shadow: 0 8px 6px -6px #000;
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
 
 
}


.show-list{
  display: flex;
  
}
.hide-list{
  display: none;
}
.show-list .nav-item{
  list-style: none;
  margin-right: 4rem;
  overflow: hidden;
  padding: 0.5rem 0rem;
 
}

.show-list .nav-item:last-child{
  margin-right: 1rem;
}
.show-list .nav-item a{
  position: relative;
  text-decoration: none;
  color: #FFF;
  font-weight: 400;
  font-size: 1.05rem;
  letter-spacing: 1px;
}
.show-list .nav-item a::before{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #fff;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
}
.show-list .nav-item:hover a::before{
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
 
}



/* .show-list .nav-item:hover a{
  
  border-bottom: 2px solid rgba(0, 0, 0);
} */
.active{
  border-bottom: 3px solid #fff;
  font-weight: 600 !important;
}
.logo{
  margin-left: 1rem;
  z-index: 999;
   
 }
 
 .logo img{
   width: 130px;
 }

.btn-toggle{
  display: none;
}
/* home page */


.full-height{
  min-height: 93vh;
  background: url(/static/media/bgshow.5a43fd16.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.showcase{
  display: flex;
  min-height: 80vh;
  align-items: center;
  padding:0 1rem;
  
}
.left-text{
  width: 60%;
  
}
.left-text h1{
  position: relative;
  opacity: 0;
  font-size: 4.4rem;
  margin-bottom: 2rem;
}
.left-text p{

  position: relative;
  -webkit-transform: translateX(-800px);
          transform: translateX(-800px);
  font-size: 1.5rem;
  color: #7B7A7A;
  /* padding: 0 2rem; */
 
}

.left-border{
  position: relative;
  overflow: hidden;
  opacity: 0;
}

/* .left-border::after{

 position: absolute;
 content: '';
 top: 0;
 left: 0;
 height: 100%;
 width: 5px;
 background: linear-gradient(180deg, #0157FF 0%, #FF0000 100%);
 
} */
.right-image{
 
  width: 40%;
 
}
.right-image img{
  
  width: 650px ;
 
}

.main-btn{
  
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #fff;
  margin: 2rem 0;
  display: inline-block;
  text-align: center;
  background: #3340FF;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.1), 0 1px 2px rgba(28,35,139,.1), 0 2px 5px rgba(41,52,204,.2);
  
}
.second-btn{
  background: #fff;
  color: #3340FF;
  margin-left: 20px;
}
.main-btn:hover{
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}


.hide-btn{
  opacity: 0;
}

.social-media{
width: 220px;
margin: 0 auto;
text-align: center;
border: 3px solid #7B7A7A;
border-radius: 10px;
padding: 0.5rem;
}
.social-media a{
  margin-right: 1rem;
  color: #7B7A7A;
}
.social-media a:last-child{
  margin-right: 0;
}
.social-media a:last-child:hover{
  color: #0157FF;
}
.social-media a:nth-child(2):hover{
  color: #D52C41;
}
.social-media a:nth-child(1):hover{
  color:black;
}
.hello-text{
  opacity: 0;
}

.mouse{
  height: 10vh;
display: flex;
justify-content: center;
}
/* Features */
.features{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  text-align: center;
  grid-gap: 20px;
  box-shadow: 0 10px 20px 0 rgba(119, 129, 173, .1);
  padding: 2rem;
  margin: 3rem 0;
}

.features i{
  color: #fff;
  z-index: 10;
}
.features-header{
  font-size: 1.4rem;
  font-weight: 600;
  padding: 2rem 0;
}
.feature-logo {
  position: relative;

  display: inline-block;
  padding: 1rem;
}
.feature-logo::before{
position: absolute;
content: '';
top: 50%;
left: 50%;
width: 100px;
height: 100px;
background:#3340FF;
-webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
-webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
z-index: -10;
}
/* My work page */
#my-work{
  padding: 3rem 0;
  text-align: center;
  background: url(/static/media/bgproject.6788c680.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom; 
  /* background-color: #3340FF; */
  /* transform: translateY(-10px); */
  /* background: linear-gradient(90deg, #03256F 0%, #11C7F1 100%); */
}


.my-work-grid-left h2{
  color: #3340FF;
}
.my-work-grid-right h1{
  padding: 1rem 0;
  font-size: 2.4rem;
}

.my-work-grid-right p{
   color:rgba(0,0,0,0.8);
   font-size: 21px;
}


.my-work-flex{
display: grid;
grid-template-columns: repeat(3,1fr);
grid-gap: 30px;
padding: 2rem 4rem;
margin-top: 2rem;

}
.my-work-card-1{
 
  /* border: 1px solid black; */
  border-radius: 4px;

}
.my-work-card-2{

 
  /* border: 1px solid black; */
  border-radius: 4px;
}
.my-work-card-3{

/* 
  border: 1px solid black; */
  border-radius: 4px;
}
.my-work-card-4{


  /* border: 1px solid black; */
  border-radius: 4px;
}
.card-my-work{

position: relative;
overflow: hidden;
box-shadow: 0 10px 20px 0 rgba(119, 129, 173, .1);
cursor: pointer;

}

.card-my-work::before{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  opacity: 0;
  z-index: 10;
 
}

.card-my-work:hover .my-work-name{
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-my-work:hover.card-my-work::before{
  opacity: 0.75;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-my-work:hover.card-my-work img{
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-my-work img{
 
  width: 100%;

}
.my-work-name{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #3340FF;
  color: white;
  text-align: center;
  padding: 1rem 0;
  -webkit-transform: translateY(+100%);
          transform: translateY(+100%);
  z-index: 999;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.card-my-work i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  z-index: 999;
  border-radius: 4px;
  opacity: 0;
  font-size: 1.5rem;
}
.card-my-work:hover.card-my-work i{

 opacity: 1;
 -webkit-transition: 0.3s;
 transition: 0.3s;
}
.my-work-header{
  position: relative;
  text-align: center;
  font-size: 2.5rem;
  display: inline-block;
  padding: 1rem 0;
  
}
.header1{
  color: #000;
}

.my-work-header::before{
  position: absolute;
  content: "";
  width: 50%;
  height: 5px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #0157FF;
}
.header1::before{
  background-color: #0157FF;
}


.small-text{
font-size: 1.4rem;
color: #000;
}

.paragraph{
  font-size:21px;
  margin-bottom:1.8rem;
  padding:1rem 0;
  color:rgba(0,0,0,0.8);
}


/* About Me */
#about-me{
  padding: 5rem 0;
  overflow: hidden;
  background: url(/static/media/bgabout.280d1af2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about-me-flex{
display: grid;
grid-template-columns: 1fr 2fr;
grid-gap: 30px;
}
.about-me-image h2{

text-align: right;
 color: #3340FF;
}
.about-me-image img{

  width: 400px;

}


.about-me-text h1{
  position: relative;
  display: inline;
 
  font-size: 2.5rem;

}
.about-me-text h1::before{
  position: absolute;
  content: "";
  bottom: 0;
  width: 50%;
  height: 5px;
  background: #0157FF;

}
.about-me-text p{
  padding: 2rem 0;
  font-size: 18px;
}
.about-me-ul{
  margin-top: 2rem;
}
.about-me-ul ul{
list-style: none;
display: grid;
grid-template-columns: repeat(4,1fr);
grid-gap: 30px;
}

/* Contact me */
#contact-me{
  padding: 4rem;
  background: url(/static/media/bgcontact.84bcb789.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

}

.contact-me-grid{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
}

.left-grid h2{
  text-align: right;
  color: #3340FF;
}




.contact-me-image{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Projects Part */








/* end of about page */

.build-with{
 
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.build-with img{
  width: 50px;
}
.build-with h1{
  margin: 0 0.5rem;
}

.header-1{
  opacity: 0;
}

/* End of Projects Page */
.project-title-header{
  display: block;
  background: #3340FF;
  color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
.project-image{
  padding: 0 2rem;
}
.project-description-title{
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
}
.project-container p{
  padding: 0 2rem;
}
.project-build-with h3{
  margin-top: 1rem;
  padding: 0 2rem;
}
.project-build-with ul{
  padding:1rem 2rem;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
}
.project-1-build ul{
  grid-template-columns: repeat(4,1fr);
}
.blue-text{
  color: #3340FF;
}
.project-build-with li {



margin-right: 10px;

 
}
.project-build-with li i{

  color: #3340FF;

  }

.project-footer{
  background: #FB9902;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
}
.project-btn-view-live{
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  background: #3340FF;
  border-radius: 4px;
  margin-right: 20px;
}
.project-btn-view-live:hover,
.project-btn-view-github:hover{
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.project-btn-view-github{
  padding: 1rem 2rem;
  text-decoration: none;
  color: #3340FF;
  background: #FFF;
  border-radius: 4px;
}
/* Modal */

.modal-outer{
  
position: fixed;
top: 0;
left: 0;
height: 100%;
width: 100%;
background: rgba(0, 0, 0,0.7);
display: flex;
justify-content: center;
align-items: center;
z-index: 999;


}
.modal-inner{
  position: relative;
background: white;
width: 900px;

border-radius: 7px;

-webkit-animation: fade-up 0.5s 0.2s 1 forwards;

        animation: fade-up 0.5s 0.2s 1 forwards;
opacity: 0;
-webkit-transform: translateY(50px);
        transform: translateY(50px);

}
@-webkit-keyframes fade-up{
  0%{
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }100%{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes fade-up{
  0%{
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }100%{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.modal input{
width: 100%;
padding: 1rem;
margin-bottom: 0.5rem;
border: 1.5px solid black;
border-radius: 4px;
}
.modal-inner h1{
  text-transform: uppercase;
  margin-bottom: 1rem;

  
}
.modal-inner h2{
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 0;
  
  
}
.modal textarea{
  width: 100%;
  padding: 1rem;
  height: 80px;
  resize: none;
  border: 1.5px solid black;
border-radius: 4px;
  }
  .modal-btn{
    
    padding: 1rem 3rem;
    font-size: 1rem;
    border:none;
    background: #3340FF;
    color: #fff;
    
    margin: 0 auto;
    cursor: pointer;
    margin-top: 1rem;
    border-radius: 6px;
  }
.close-btn{
  position: absolute;
  right: 2rem;
  top: 20px;
  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
}
.icon{
  font-size: 5rem;
 
}

/* media queries for tablet */
@media(max-width:1000px){


  .show-list{
    display: none;
  }
   
  #my-work{
    padding: 2rem;
  }
  .my-work-flex{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    
    }
 
    #contact-me-p{
      width: 100%;
    }
    .modal{
      width: 100%;
    }
  .hide-list{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 998;
  }
  .hide-list .nav-item{
    opacity: 0;
    list-style: none;
    margin: 2rem 0;
    -webkit-animation: fade 1s 1 forwards;
            animation: fade 1s 1 forwards;
     
  }
  .hide-list .nav-item:first-child{
  
    -webkit-animation-delay: 0.1s;
  
            animation-delay: 0.1s;
  }
  .hide-list .nav-item:nth-child(2){
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .hide-list .nav-item:nth-child(3){
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .hide-list .nav-item:last-child{
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .hide-list .nav-item a{
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
  }


.btn-toggle{
  position: relative;
  margin-right: 1rem;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
}
.line{
  width: 35px;
  height: 5px;
  border-radius: 7px;
  background: #fff;
 
}
@-webkit-keyframes fade {
  0%{
    opacity: 0;
 
  }
  100%{
    opacity: 1;
   
  }
}
@keyframes fade {
  0%{
    opacity: 0;
 
  }
  100%{
    opacity: 1;
   
  }
}
.line1{
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.line3{
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
}


  .left-text h1{
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .left-text p{
    position: relative;
    font-size: 1.3rem;
    padding: 0 1.2rem;
  }
  .about h1:first-child{
    margin-top: 1rem;
  }
  .about h1{
    font-size: 1.8rem;
  
  }
  .about p,
  .about ul li{
    font-size: 1.3rem;
  }
  .flex-3{
    width: 95%;
  }

  .main-text{
    margin-top: 1rem;
  }
  
 .about-me-flex{
   flex-direction: column;
 }
 .about-me-image img{
   width: 400px;
 }
 .right-image img{
   width: 100%;
 }
 .mouse{
   display: none;
 }
}

/* media queries for mobile */
@media(max-width:670px){
  
  .my-work-flex{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 0;
    }
    /* .full-height{
      background: #fff;
    } */
  .showcase{
  
    flex-direction: column;
    align-items: baseline;
    min-height: 80vh;
    justify-content: center;
  }
  .left-text{
    width: 100%;
  order: 2;
  margin-top: 2rem;
  }
 
  .right-image{
    width: 100%;
    order: 1;
    text-align: center;
  }
  .right-image img{
   display: none;
  }

  .second-btn{
display: none;
  }




.mouse{display: none;}

.small-text{
text-align: left;
  }
  
  .paragraph{
    text-align: left;
  }
.my-work-header{
  text-align: left;
  font-size: 2rem;
}
.about-me-text h1{
  font-size: 2rem;
}
.my-work-header::before{
  left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

#my-work{
  padding: 1rem;
}

.contact-me-wrapper{
  text-align: left;
}


    .logo img{
      width: 100px;
    }
    .build-with img{
      width: 30px;
    }
    .modal-inner h1{
    font-size: 1.5rem;
      
      
    }
  
.about-grid ul {
  grid-template-columns: repeat(3,1fr);
}
.projects ul li{
  font-size: 0.8rem;
}
.about p{
  font-size: 18px;
}
.left-text p{

  font-size: 1.1rem;

}
#about-me{
  padding: 3rem 0;
}
.features-header{
padding: 1rem 0;
}

.about-me-flex{
 grid-template-columns: 1fr;
 grid-gap: 10px;
}
.about-me-image h2
{
text-align: left;
padding:0 1rem;
}
.left-grid h2{
  text-align: left;
  padding:0 ;
}

.contact-me-grid{
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.about-me-text{
  padding: 1rem;
}
.about-me-image img{
  width: 300px;
}




.about-me-ul ul{
  grid-template-columns: repeat(3,1fr);
}
#contact-me{
  padding: 1rem;

}
#contact-me-p{
  width: 100%;
  font-size: 18px !important;
}
.contact-me-flex{
display: flex;
justify-content: center;


}


.modal{
  width: 100%;
}
.modal-inner{
  width: 100%;
}
.project-build-with ul,
.project-build-with h3{
  display: none;
}
.project-container p{
  margin-bottom: 20px;
}
.features{
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
}
.my-work-grid-right p{
  font-size: 16px;
}


}
